
export const footerData = [
    {
        id:"00",
        topic : [
            'The player',
            'Overview',
            'Community',
            'Download',
            'Fanzone'
        ]
    },
    {
        id:"01",
        topic:[
            'Creators',
            'Overview',
            'Services',
            'Pricing',
            'Fanzone'
        ]
         
    },
    {
        id:"02 ",
        topic:[
            'Company',
            'About us',
            'Careers',
            'Foundation',
            'Help Initiative',
            'For Partners',
            'Press Kit',
            'Tribune'
        ]
    }


]